import React from "react";
import "./stars.scss";
import Typed from "react-typed";
import bigImage from "../img/bg2.jpg";
 
class Intro extends React.Component {
  render() {
    return (
      // <div id="home" className="intro route bg-image background">
      <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />

        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <h1 className="intro-title mb-4">
                {window.location.href.indexOf('resume') === -1 ? (
                <Typed
                    strings={[
                      "Kia Ora",
                      "Hello",
                      "Hey there",
                    ]}
                    typeSpeed={10}
                    backDelay={5000}
                    backSpeed={30}
                    loop
                  />
                 ) : "Hey there"} , I am Clayton</h1>
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
                <strong className="text-slider">
                {window.location.href.indexOf('resume') === -1 ? (
                  <Typed
                    strings={[
                      "Full stack Developer",
                      "Software Engineer",
                      "App Developer",
                      "DevOps Engineer",
                      "Tech Lead",
                      "Startup Founder",
                      "Autodidact",
                      "Creative",

                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
                ) : "Software Engineer"}
                </strong>
              </p>
              {window.location.href.indexOf('resume') === -1 && (
              <p className="pt-3">
                <a
                  className="btn btn-light btn js-scroll px-4"
                  href="#work"
                  role="button"
                >
                  View My Work
                </a>
              </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
