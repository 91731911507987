import React from "react";

//import stock
import twentythreeshout from "../img/23shout.png";
import stock from "../img/image2.jpg";
import battlefit from "../img/battlefit.png";
import arataki from "../img/arataki.png";
import deliverybizconnect from "../img/deliverybizconnect.png";
import kaitiaki from "../img/kaitiaki.png";
import ios from "../img/ios.svg";
import google from "../img/google.png";
import moment from "moment";


class Skills extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: {
        'Front End': [        
          { id: "HTML5_skill", content: "HTML5/CSS3/JS", porcentage: "100%", value: "100" },
          {
            id: "Typescript_skill",
            content: "Typescript",
            porcentage: "98%",
            value: "98"
          },
          {
            id: "Angular_skill",
            content: "Angular",
            porcentage: "95%",
            value: "95"
          },
          {
            id: "React_skill",
            content: "React",
            porcentage: "90%",
            value: "90"
          },
          {
            id: "Vue",
            content: "Vue",
            porcentage: "90%",
            value: "90"
          },
        ],
        'Back End': [        
          {
            id: "Python_skill",
            content: "Python",
            porcentage: "75%",
            value: "75"
          },
          { id: "PHP_skill", content: "PHP", porcentage: "97%", value: "97" },
          { id: "lara_skill", content: "Laravel", porcentage: "95%", value: "95" },
          {
            id: "Node",
            content: "Node",
            porcentage: "90%",
            value: "90"
          },
          { id: "Mysql", content: "Mysql", porcentage: "95%", value: "95" },
        ],
        'Mobile': [        
        {
          id: "ReactNative_skill",
          content: "React Native",
          porcentage: "90%",
          value: "90"
        },
        {
          id: "Ionic",
          content: "Ionic | Cordova",
          porcentage: "90%",
          value: "90"
        },
        {
          id: "Flutter",
          content: "Flutter",
          porcentage: "60%",
          value: "60"
        },
        ],
        'Cloud': [  
          { id: "Google", content: "Google Cloud", porcentage: "85%", value: "85" },
          { id: "AWS", content: "AWS", porcentage: "85%", value: "85" },
        ],
        'DevOps/CICD': [
          { id: "Jenkins", content: "Jenkins", porcentage: "55%", value: "55" },
          { id: "CodeMagic", content: "CodeMagic", porcentage: "65%", value: "65" },        
          { id: "ga", content: "Github Actions", porcentage: "65%", value: "65" },        
          { id: "ga", content: "Bitbucket Pipelines", porcentage: "65%", value: "65" },        
        ],
        'Web3': [
          { id: "NFT", content: "NFT", porcentage: "75%", value: "75" },
          { id: "SmartContracts", content: "Smart Contracts", porcentage: "65%", value: "65" },
        ]
      },
    }
  }

  render() {
    return (
      <section id="work" className="portfolio-mf route">
        <div className="container">
          <div className="row skills-hero">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Skills</h3>
                <p className="subtitle-a">
                  What's in my wheelhouse
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>

          
          <div className="skill-mf">
            <p className="title-s">Skills</p>
            <div className="row">
              {Object.keys(this.state.skills).map(cat => {
                return <>
                <div class="col col-xs-12 col-md-6 ">                
                  <h6 className="skill-header">{cat}</h6>
                  {this.state.skills[cat].map((skill) => {
                    
                    return <React.Fragment key={skill.id}>
                        <div>
                          <span>{skill.content}</span>{" "}
                          <span className="pull-right">
                            {skill.porcentage}
                          </span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: skill.porcentage }}
                              aria-valuenow={skill.value}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </React.Fragment>              
                  })}
                </div>
                </>                
              })}
            </div>
          </div>

        </div>

      </section>
    );
  }
}

export default Skills;
