import React from "react";
import myImage from "../img/myImage.png";
import Skills from './skills';

class About extends React.Component {
  constructor() {
    super();
    this.state = {      
      about_me: [
        {
          id: "first-p-about",
          content:
            "Hello there! Clayton here... Thanks for taking the time to checkout my digital resume. " + 
            "I have over 13 years experience as a software engineer and have worked predominantly within " +
            "the startup space in different capacities but mainly in Tech Lead / CTO roles."
        },
        {
          id: "third-p-about",
          content:
            "I'm an autodidact, which means I do not possess any pieces of paper or tertiary level education " +
            ", more importantly I have real-world experience behind me. I'm a passionate person who's motivated by knowledge and learning new technologies. " 
        },
        {
          id: "second-p-about",
          content:
            "I love building things. While hard engineering problems are often intrinsically fun to tackle, " +
            "I'm most attracted to solving real customer problems with a business justification. "
        },
        {
          id: "fourth-p-about",
          content:
            "Over the years, I've lead small engineering teams, mentored Junior Developers, founded companies " +
            "and brought many products to market."
        },        
        {
          id: "fifth-p-about",
          content:
            "I have a very curious mind and that has enabled me to amass extensive knowledge in all areas of product life cycles."
        },
        {
          id: "sixth-p-about",
          content:
            "From concept through to autonomous product delivery, " +
            "I enjoy creating frictionless processes to improve efficiency and output"
        },
        {
          id: "sevent-p-about",
          content:
            "For the last 6 years, I've worked remotely for companies in NZ and Australia, " +
            "and I believe, communication is the most important attribute " +
            "to create productive outcomes within a remote working environment." 
        },

        {
          id: "eight-p-about",
          content:
            "Most recently, among other things, I've been exploring the world of Web3 and how that's going " +
            "to impact the future of products brought to market, so I've started to implement glimpses of " +
            "Web3 into my project BattleFit, which is an exciting journey to get my feet wet within the FitFi/Move2Earn space." 
        },
        {
          id: "nine-p-about",
          content: <strong>I am available for contract work or full-time </strong>
            
        },

      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <h3 className="title-a">About Me</h3>
                  <p className="subtitle-a">
                    A lil snippet about me
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="box-shadow-full">
              <div className="row skills">
                {window.location.href.indexOf('resume') === -1 && (
                <div className="col-md-6">                   
                  <Skills />
                </div>
                )}
                <div className={window.location.href.indexOf('resume') === -1 ? "col-md-6": ""}>
                  <div className="about-me pt-4 pt-md-0">
                    {this.state.about_me.map(content => {
                      return (
                        <p className="lead" key={content.id}>
                          {content.content}
                        </p>
                      );
                    })}

                    {window.location.href.indexOf('resume') === -1 && (
                    <p className="lead">
                    If you have any questions or just want a chat, feel free to <a href="#contact">send me a message</a>
                    </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }
}

export default About;
