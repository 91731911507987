import React from "react";

//import stock
import twentythreeshout from "../img/23shout.png";
import stock from "../img/image2.jpg";
import battlefit from "../img/battlefit.png";
import arataki from "../img/arataki.png";
import deliverybizconnect from "../img/deliverybizconnect.png";
import kaitiaki from "../img/kaitiaki.png";
import ios from "../img/ios.svg";
import google from "../img/google.png";
import moment from "moment";

const portfolio = [{
  image: twentythreeshout,
  title: '23shout',
  subTitle: 'Telecommunications',
  started_at: '2022-02-15',
  role: "Senior Software Engineer",
  about: "23shout is a telecommunications platform that aims to create a frictionless calling experience for sales teams",
  duties: "As the lead software engineer, I worked closely with the product owner to deliver a cross platform calling solution for high output call centers. I was in charge of all aspects of the tech stack including web, app and api deployment.",
  tech_stack: {
    front_end:[
      "React",
      "React Native",
      "Flutter",
      "Typescript",
      "Javascript",
      "iOS Development",
      "Android Development",
      "Gatsby",
    ],
    back_end: [
      "Python", 
      "Flask", 
      "SqlAlchemy",
      "Web sockets",
      "Mysql",
    ],
    infrastructure: [
      "AWS",
      "Elastic Beanstalk", 
      "Amplify",
    ],
    devops:[
      "CodeMagic CI/CD",                      
      "Github - Github Actions"
    ],
    other: [
      "Jira"
    ],
  }
  ,
  extra: <>
    <p>
      I loved the extremely polished platform we built at 23 and the flexibility of the entire tech stack,
      which enabled us to ship updates quickly and respond to customer feedback promptly.

      <br /><br />

      23shout is looking to raise a Series A investment, with Skalata Ventures at the helm.
    </p>

  </>,
  links: <>
    <a class="btn btn-primary" href="https://23shout.com" target="_blank">Website</a>
    <a class="btn" href="https://apps.apple.com/us/app/23shout/id1608318473" target="_blank"><img src={ios} /></a>
    <a class="btn" href="https://play.google.com/store/apps/details?id=com.a23shout.mobile" target="_blank"><img src={google} /></a>
  </>
}, 
{
  image: battlefit,
  title: 'BattleFit',
  subTitle: 'Fitness | FitFi | Web3',
  started_at: '2012-06-15',
  role: "Founder & CTO",
  about: `BattleFit is "The Social Workout". It is a Fitness
  app that is available on both iOS and Android, aiming to motivate individuals to lead a
  healthy life by working out with your friends regardless of where they are.`,
  duties: "Built an app that is used by thousands of users daily.",
  tech_stack: {
    front_end:[
      "Angular",
      "Typescript",
      "Javascript",
      "React",
      "React Native",
      "iOS Development",
      "Android Development",
    ],
    back_end: [
      "Node.js",
      "Express"
    ],
    infrastructure: [
      "Firebase",
      "Realtime DB", 
      "Firestore", 
      "Google Cloud",
      "Cloud Functions",
    ],
    devops:[
      "CodeMagic CI/CD",                      
      "Github - Github Actions",
      "Jenkins",
    ],
    other: [
      "Web3 - FitFi",
      "NFTs",
      "Jira"
    ],
  },
  extra: <>
    <p>
      I started BattleFit as a hobby with a few friends in 2012 as a project to teach me how to build 
      mobile apps (back when they weren't that popular). It quickly grew into something that the world wanted.
      In 2015 we entered in to the Surf Accelerator Program on the Gold Coast, which we eventually won and 
      received a $20k office within a co-working space for a year. During covid, the demand for the app 
      exploded as a means for people to continue working out from home.

      <br /><br />

      I'm currently in the process of implementing Web3 to bring BattleFit into the FitFi space which is
      set to expand in the coming years.
    </p>    
  </>,
  links: <>
    <a class="btn btn-primary" href="https://battlefitapp.com" target="_blank">Website</a>
    <a class="btn" href="https://apps.apple.com/nz/app/battlefit/id687202056" target="_blank"><img src={ios} /></a>
    <a class="btn" href="https://play.google.com/store/apps/details?id=com.battlefit.app&hl=en_NZ&gl=US" target="_blank"><img src={google} /></a>
  </>
},
{
  image: arataki,
  title: 'Arataki',
  subTitle: 'Proximity delivered content',
  role: 'Tech Lead',
  started_at: '2016-12-01',
  about: `ACT is an app that utilizes 
  proximity technology to deliver cultural content to users when in location.`,
  duties: `Built the platform from the ground-up, which entailed
  an admin CMS, mobile app and public website.`,
  tech_stack: {
    front_end:[
      "iOS Development",
      "Android Development",
      "Angular",
      "Typescript",
      "Javascript",
    ],
    back_end: [
      "PHP",
      "Laravel",
    ],
    infrastructure: [
      "Google Cloud",
    ],
    devops:[
      "CodeMagic CI/CD",                      
      "Github - Github Actions",
      "Jenkins",
    ],
    other: [
      "Jest",
      "Bluetooth (BLE)",
      "GPS",
      "QR Scan and Generation",
      "Augmented Reality",
      "Jira"
    ],
  },
  extra: <>
    <p>
      I was approached by some old school friends who had a concept, which was to deliver Culturally
      significant stories when in location. I utilized bluetooth beacons and GPS technologies to 
      achieve this and went from concept to market in a few short months. During this time, we 
      entered in the Kokiri Accelerator program in Auckland and took out second place, which gave us
      great exposure and introductions to many companies including Meta (formerly known as Facebook), 
      Spark, Air NZ and the NZ Police.<br /><br />

      ACT is still operational and is used across NZ, although the company now focusses more on the
      consulting side of the cultural landscape.
    </p>
    
  </>,
  links: <>
    <a class="btn btn-primary" href="https://arataki.app" target="_blank">Website</a>                  
  </>

},
{
  image: stock,
  title: 'Explore Heretaunga',
  subTitle: 'Augmented Reality Story Telling',
  about: `A story telling app utilizing AR (augmented reality)
  to bring historical stories to life around Heretaunga (Hastings)`,
  role: 'Software Engineer',
  started_at: '2022-01-01',
  //ended_at: '2022-02-14',
  duties: `Built the platform to deliver AR experiences around the city of 
  Hastings`,
  tech_stack: {
    front_end:[
      "iOS Development",
      "Android Development",
      "Angular",
      "Typescript",
      "Javascript",
    ],
    back_end: [
      "PHP",
      "Laravel",
      "Mysql"
    ],
    infrastructure: [
      "Google Cloud"
    ],
    devops:[
      "CodeMagic CI/CD",                      
      "Github - Github Actions",
      "Jenkins",
    ],
    other: [
      "WebXR",
      "Augmented Reality",
      "Blender (3d models and assets)",
    ],
  },
  extra: <>
    <p>
      In combination with Indelible Creative Studio (who provided the 3d assets) and Hastings City Council,
      we built an exciting app that can be used around the city to unlock interactive AR experiences.
    </p>
  </>

},
{
  image: deliverybizconnect,
  title: 'DeliveryBizConnect',
  subTitle: 'Food Delivery Platform',
  started_at: '2009-06-01',
  ended_at: '2022-02-14',
  about: `Based in Canada, DBC aim was to reduce
  the costs for food delivery and pass those savings on to restaurants and customers`,
  role: 'Tech Lead',
  duties: `Within a team of 6, I lead the technology side of things
  to ensure our system had impeccable uptime and realtime data to ensure all stakeholders were 
  always in the loop`,
  tech_stack: {
    front_end:[
      "iOS Development",
      "Android Development",
      "Angular",
      "Typescript",
      "Javascript",
    ],
    back_end: [
      "PHP",
      "Laravel",
      "SQL Server",
      "Node.js",
    ],
    infrastructure: [
      "Firestore"
    ],
    devops:[
    ],
    other: [
      "Jira"
    ],
  },

}]
class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Experience</h3>
                <p className="subtitle-a">
                  A sneak peak into some of my works
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>

          <div className="row">
            {portfolio.map((el) => {
              return (
                <div className="col-md-12">
                  <div className="work-box">
                    {window.location.href.indexOf('resume') === -1 && (
                    <div className="work-img">
                      <img src={el.image} alt="" className="img-fluid" />
                    </div>
                    )}
                    <div className="work-content">
                      <h2 className="w-title">
                        {el.title} 
                        <span className="timespan">
                          {el.started_at ? moment(el.started_at).format('MMM YYYY') :''} - {el.ended_at ? `${moment(el.ended_at).format('MMM YYYY')}` : 'Present'}
                        </span>
                      </h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          {el.subTitle}
                        </span>
                      </div>

                      <div className="work-description">                      
                        <p>
                          <strong>Role</strong><br />
                          {el.role}
                        </p>
                        <p>
                          <strong>About {el.title}</strong><br /> {el.about}
                        </p>

                        <p>
                          <strong>Duties</strong><br /> 
                          {el.duties}
                        </p>

                        {el.extra && (
                          <div className="extra">
                            <strong>My two-cents</strong><br /> 
                            {el.extra}                            
                          </div>
                        )}

                        <p>
                          <strong>Tech Stack</strong><br /> 
                          {el.tech_stack && (
                            <div class="md-chips">
                              {Object.keys(el.tech_stack).map((key) => {

                                return <>
                                  {el.tech_stack[key].map((tech) => 
                                    <div class={"md-chip " + key}>
                                      {tech}
                                    </div>
                                  )}
                                </>
                                }
                              )}
                            </div>
                          )}
                        </p>

                        {window.location.href.indexOf('resume') === -1 && el.links && (
                          <div className="links">
                            {el.links}
                          </div>
                        )}

                      </div>
                      
                      
                    </div>
                  </div>
                </div>
              )
            })}
            
          </div>
        </div>

      </section>
    );
  }
}

export default Portfolio;
