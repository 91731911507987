import React from "react";

//import stock
import engadget from "../img/engadget.png";
import companiesnz from "../img/companiesnz.png";
import crunchbase from "../img/crunchbase.png";
import kmh from "../img/kmh.png";
import kokiri from "../img/kokiri.png";
import surf from "../img/surf.png";

class AsSeenIn extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Featured In</h3>
                <p className="subtitle-a">
                  You may have seen me or my projects around
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="work-box">
                <a href={"https://www.kmh.nz/speaker/clayton-low/"}>
                  <div className="work-img">
                    <img src={kmh} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">Ko Māui Hangarau</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Keynote Speaker
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </a>
                
              </div>
            </div>
            <div className="col-md-3">
              <div className="work-box">
              <a href={"https://kokiri.nz/portfolio-item/arataki/"} target="_blank">
                  <div className="work-img">
                    <img src={kokiri} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">Kōkiri</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Startup Accelerator
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </a>
                
              </div>
            </div>
            <div className="col-md-3">
              <div className="work-box">
              <a href={"https://www.linkedin.com/pulse/20141121062844-19712647-gold-coast-demo-day-shines/"} target="_blank">
                  <div className="work-img">
                    <img src={surf} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">SURF</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Startup Accelerator
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </a>
                
              </div>
            </div>
            {window.location.href.indexOf('resume') === -1 && (
                              
            <div className="col-md-3">
              <div className="work-box">
                <a href={"https://www.companiesnz.com/person/2386561/clayton-robert-low"} data-toggle="modal" data-target="#battlefit-modal">
                  <div className="work-img">
                    <img src={companiesnz} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">Companies NZ</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            Various Shareholdings in Startups
                          </span>{" "}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </a>
                
              </div>
            </div>
            )}
            
            <div className="col-md-3">
              <div className="work-box">
              <a href={"https://www.crunchbase.com/organization/battlefit/"} target="_blank">
                  <div className="work-img">
                    <img src={crunchbase} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">Crunchbase</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            BattleFit
                          </span>
                          {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </a>
                
              </div>
            </div>
            <div className="col-md-3">
              <div className="work-box">
              <a href={"https://www.engadget.com/2014-11-25-get-fit-with-friends-with-battlefit.html"}>
                  <div className="work-img">
                    <img src={engadget} alt="" className="img-fluid" />
                  </div>
                  <div className="work-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="w-title">Engadget</h2>
                        <div className="w-more">
                          <span className="w-ctegory">
                            BattleFit
                          </span>
                        </div>
                      </div>                     
                    </div>
                  </div>
                </a>
               
              </div>
            </div>
            
            
          </div>
        </div>
      </section>
    );
  }
}

export default AsSeenIn;
