import React from "react";
import "./stars.scss";
import Typed from "react-typed";

import Navbar from './navbar.jsx';
import Intro from './intro.jsx';
import About from './about.jsx';
import Portfolio from './portfolio.jsx';
import Contact from './contact.jsx';
import AsSeenIn from './as-seen-in.jsx';
import BackToTop from './back-top.jsx';
import Preloader from './preloader';
import CoverLetter from './cover-letter';
import Skills from "./skills";

class Base extends React.Component {
  render() {
    return (
      <div className={window.location.href.indexOf('resume') > -1 ? "resume": ""}>
        <Navbar />
        <Intro />

        {false && (
          <CoverLetter />
        )}
        <About />
        {window.location.href.indexOf('resume') > -1 && (
          <Skills/>
        )}
        <Portfolio />
        {window.location.href.indexOf('resume') === -1 && (
          <>
          <AsSeenIn />
            <Contact />
            <BackToTop />
          </>
        )}
        <Preloader />
      </div>
    );
  }
}

export default Base;
