import React from "react";
import myImage from "../img/myImage.png";
import Skills from './skills';

class CoverLetter extends React.Component {
  constructor() {
    super();
    this.coverLetter = `test`
  }

  render() {
    return (
      <section className="route">
        <div className="container">
          <div className="box-shadow-full">
            <div className="row">
              {this.coverLetter}
            </div>
          </div>        
      </div>
    </section>
    );
  }
}

export default CoverLetter;
